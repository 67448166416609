import { HttpStatusCode } from '@solidjs/start';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { Button, Heading, Link, Pagination, Picture } from '@troon/ui';
import { cache, createAsync, useParams } from '@solidjs/router';
import { For, Show, Suspense } from 'solid-js';
import { isServer } from 'solid-js/web';
import { Link as HeadLink, Title } from '@solidjs/meta';
import { IconArrowLeftMd } from '@troon/icons';
import { Content } from '../../../../components/content';
import { getConfigValue } from '../../../../modules/config';
import { getReleasesServer } from '../api/list';
import type { Release } from '../api/[release]';

dayjs.extend(utc);
dayjs.extend(tz);

export default function PressReleasePaginated() {
	const params = useParams<{ page?: string }>();
	const releases = createAsync(() => getReleases(parseInt(params.page ?? '1', 10)), { deferStream: true });

	return (
		<Content class="flex flex-col gap-8">
			<Title>
				Press Releases<Show when={params.page}>{(page) => ` | Page ${page()}`}</Show> | Troon
			</Title>
			<Heading as="h1">Press Releases</Heading>
			<Show when={params.page === '1'}>
				<HeadLink rel="canonical" href="/press-releases" />
			</Show>

			<div class="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
				<Suspense>
					<For
						each={releases()?.releases}
						fallback={
							<div class="flex flex-col gap-8">
								<HttpStatusCode code={404} />
								<p>No press releases found.</p>
								<Button class="w-fit" as={Link} href="/press-releases">
									<IconArrowLeftMd />
									Back to Press Releases
								</Button>
							</div>
						}
					>
						{(release, i) => (
							<article class="group relative flex flex-col gap-2">
								<div class="overflow-hidden rounded">
									<Picture
										src={`${getConfigValue('IMAGE_HOST')}/digital/press-releases/${release.image}`}
										alt=""
										sizes={[[480, 270]]}
										class="size-full transition-transform duration-200 group-hover:scale-105"
										crop="face"
										loading={i() < 6 ? 'eager' : 'lazy'}
									/>
								</div>
								<Link
									href={`/press-releases/${release.slug}`}
									class="block text-lg font-semibold after:absolute after:inset-0"
								>
									{release.title}
								</Link>
								<time class="text-sm">{dayjs(release.date, 'America/Phoenix').format('MMMM D, YYYY')}</time>
							</article>
						)}
					</For>
				</Suspense>
			</div>
			<Suspense>
				<Show when={releases()?.releases?.length}>
					<Show when={releases()}>
						{(data) => (
							<div class="mt-10 border-t border-neutral py-10">
								<Pagination
									currentPage={parseInt(params.page ?? '1', 10)}
									firstPage={1}
									lastPage={data()!.pages}
									url={(page) => `/press-releases/page/${page}`}
									nextTitle="Older"
									prevTitle="Newer"
								/>
							</div>
						)}
					</Show>
				</Show>
			</Suspense>
		</Content>
	);
}

const getReleases = cache(async (page: number) => {
	if (isServer) {
		return getReleasesServer(page);
	}
	const res = await fetch(`https://${getConfigValue('HOST')}/press-releases/api/list?page=${page}`);
	if (!res.ok) {
		throw new Error('Unable to load releases');
	}
	return res.json() as Promise<{
		releases: Array<Release>;
		pages: number;
		current: number;
		next?: number;
		previous?: number;
	}>;
}, 'get-press-releases');
